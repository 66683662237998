import { mapSliceToComponent } from '~/imports/universal-page'
import { UNIVERSAL_FETCH_LINKS } from '~/lib/fetch-links'

import SliceManager from '~/components/SliceManager'

import {
  getStaticPathsFunction,
  getStaticPropsFunction,
} from '~/data/page-data'

function Universal({ slices }) {
  return (
    <SliceManager
      mapSliceTypeToComponent={mapSliceToComponent}
      slicesData={slices}
    />
  )
}

export default Universal

export const getStaticProps = getStaticPropsFunction({
  type: 'universal_page',
  fetchLinks: UNIVERSAL_FETCH_LINKS,
})

export const getStaticPaths = getStaticPathsFunction({ type: 'universal_page' })
